import { useState } from "react";
import { Link } from "react-router-dom";
import "../../css/navigation.css";
import { logout } from "../../firebase";
import logoutIcon from "../../images/logout.svg";

const Navigation = props => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div>
      {
        props.loggedIn !== true 
          ? "" 
          : <div>
              <div 
                onClick={() => { setNavOpen(!navOpen); }} 
                className={navOpen ? "burger-menu open" : "burger-menu"}
              >
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
              <nav className={navOpen ? "open" : ""}>
                <Link to="home" className={navOpen ? "open" : ""}><div>Home</div></Link>
                <Link to="dev" className={navOpen ? "open" : ""}><div>dev</div></Link>
                <Link to="gfx" className={navOpen ? "open" : ""}><div>gfx</div></Link>
                <Link to="other" className={navOpen ? "open" : ""}><div>other</div></Link>
                <button className="logoutButton" onClick={logout}><img src={logoutIcon} alt="Logout" /></button>
              </nav>
            </div>  
      }
    </div>
  );
}

export default Navigation;