import { Outlet, Navigate } from "react-router-dom";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const PrivateRoutes = () => {
  const [user] = useAuthState(auth);
  //let auth = {'token' : true};
  return(
    user ? <Outlet /> : <Navigate to="/login" />
  )
}

export default PrivateRoutes;